import $ from 'jquery';

// Modules
import ScrollToHandler from './modules/scroll-to';
import TimelineHandler from './modules/timeline';
import OdometerHandler from './modules/odometer';
import VideoHandler from './modules/video';
import FormHandler from './modules/form';

$('html').removeClass('no-js').addClass('js');
$(() => {
    new ScrollToHandler;
    new TimelineHandler;
    new OdometerHandler;
    new VideoHandler;
    new FormHandler;
});
