import $ from 'jquery';

export default class TimelineHandler {
    constructor(selector = '.js-timeline', footerSelector = '.js-footer') {
        this.$timeline = $(selector);
        this.$footer = $(footerSelector);

        if ( !this.$timeline.length ) {
            return;
        }

        this._initializeRaf();
    }

    _initializeRaf() {
        require.ensure([], (require) => {
            const raf = require('raf');

            let previousScrollTop;
            const $window = $(window);
            const $timeline = this.$timeline;
            const $footer = this.$footer;
            const $events = this.$timeline.find('.timeline-event');

            raf(function tick() {
                const currentScrollTop = $window.scrollTop();

                if ( currentScrollTop !== previousScrollTop ) {
                    // Footer
                    $footer.toggleClass('is-active', currentScrollTop > $timeline.offset().top);

                    // Events
                    const trigger = 0.55;
                    const offset = currentScrollTop + ($window.height() * trigger);

                    let $currentEvent = $events.first();
                    $events.each((i, event) => {
                        const $event = $(event);

                        if ( offset >= $event.offset().top ) {
                            $currentEvent = $event;
                        }
                    });

                    if ( !$events.filter('.is-active').is($currentEvent) ) {
                        $events.removeClass('is-active');

                        // Lazyload image for the next element and all previous elements when they haven't been preloaded yet
                        const $images = $currentEvent.next().next().prevAll().find('img[data-src]');
                        if ( $images.length ) {
                            $images.each((i, image) => {
                                const $image = $(image);
                                $image.attr('src', $image.attr('data-src')).removeAttr('data-src');
                            });
                        }

                        $currentEvent.addClass('is-active');
                    }
                }

                previousScrollTop = currentScrollTop;
                raf(tick);
            });
        });
    }
}
