/* global YT */
import $ from 'jquery';

export default class VideoHandler {
    constructor(selector = '.js-video', stateSelector = '.video-overlay-state', options = {}) {
        this.selector = selector;
        this.stateSelector = stateSelector;
        this.$videos = $(selector);
        if ( !this.$videos.length ) {
            return;
        }

        this.options = Object.assign({
            color: '#84aa13',
            loop: true,
            modestbranding: true,
            showinfo: true
        }, options);

        this.players = {};
        this._inject(() => {
            this._setupVideos();
            this._setupOverlays();
        });
    }

    _inject(callback) {
        let tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        if ( callback ) {
            window.onYouTubeIframeAPIReady = callback;
        }
    }

    _setupVideos() {
        this.$videos.each((i, video) => {
            const $video = $(video);

            this.players[$video.data('id')] = new YT.Player(video, Object.assign(this.options, {
                videoId: $video.data('id')
            }));
        });

        require.ensure([], (require) => {
            const fitvids = require('fitvids');
            fitvids('.video-overlay-player-wrapper');
        });
    }

    _setupOverlays() {
        $(this.stateSelector).on('change', (e) => {
            const $state = $(e.currentTarget),
                  $video = $state.next('.video-overlay').find(this.selector),
                  player = this.players[$video.data('id')];

            if ( $state.is(':checked') ) {
                player.playVideo();
            } else {
                player.pauseVideo();
            }
        });
    }
}
