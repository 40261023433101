import $ from 'jquery';
import 'jquery.easing';

export default class ScrollToHandler {
    constructor(selector = '.js-scroll-to', options = {}) {
        this.options = Object.assign({
            duration: 1000,
            easing: 'easeInOutQuart'
        }, options);

        $(document).on('click', selector, this._handleScrollTo.bind(this));
    }

    _handleScrollTo(e) {
        e.preventDefault();
        const $element = $(e.currentTarget),
              href = $element.attr('href');

        if ( !href ) {
            return;
        }

        const $target = $(href);
        if ( !$target.length ) {
            return;
        }

        const offset = Math.floor($target.offset().top - Math.max(($(window).height() / 2) - ($target.outerHeight(true) / 2), 0));
        $('html, body').stop().animate({ scrollTop: offset }, this.options.duration, this.options.easing, () => {
            history.replaceState(null, '', href);
        });
    }
}
