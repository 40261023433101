import $ from 'jquery';

export default class FormHandler {
    constructor(selector = '.js-form') {
        this.$form = $(selector);
        if ( !this.$form.length ) {
            return;
        }

        require.ensure([], (require) => {
            require('parsleyjs');
            require('parsleyjs/src/i18n/nl');

            this.$form.parsley();
        });
    }
}
