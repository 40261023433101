import $ from 'jquery';

export default class OdometerHandler {
    constructor(selector = '.js-odometer') {
        this.$odometers = $(selector);
        if ( !this.$odometers.length ) {
            return;
        }

        this.initialize();

        // Scrolling
        this.$window = $(window);
        this.$window.on('scroll', this._handleScroll.bind(this)).trigger('scroll');
    }

    initialize() {
        require.ensure([], (require) => {
            const Odometer = require('odometer');

            let maxLength = 0;
            this.$odometers.each((i, odometer) => {
                const length = parseInt($(odometer).data('target')).toString().length;
                if ( length > maxLength ) {
                    maxLength = length;
                }
            });

            const defaultDuration = 2500;
            this.$odometers.each((i, odometer) => {
                const $odometer = $(odometer),
                      value = parseInt($odometer.data('target')).toString();

                const od = new Odometer({
                    value: `1${Array(value.length).join(0)}`,
                    el: odometer,
                    duration: $odometer.data('duration') || defaultDuration,
                    theme: 'minimal',
                    format: 'd'
                });

                if ( value.length < maxLength ) {
                    $odometer.prepend('<span class="odometer-pad">0</span>');
                }

                $odometer.data('od', od);
            });

            this.$window.trigger('scroll');
        });
    }

    _handleScroll() {
        const modifier = 0.95,
              offsetTop = this.$window.scrollTop() + (this.$window.height() * modifier);

        this.$odometers.filter(':not(.is-initialized)').each((i, odometer) => {
            const $odometer = $(odometer);

            if ( offsetTop > $odometer.offset().top ) {
                const od = $odometer.data('od');
                if ( !od ) {
                    return;
                }

                $odometer.addClass('is-initialized');
                od.update($odometer.data('target'));
            }
        });
    }
}
